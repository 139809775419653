<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
     
<div id="app">
      <div id="chart">
      <div class="toolbar">
        <button id="one_month"
            @click="updateData('one_month')" :class="{active: selection==='one_month'}"
            >
          1M
        </button>
        
        <button id="six_months"
            @click="updateData('six_months')" :class="{active: selection==='six_months'}"
            >
          6M
        </button>
        
        <button id="one_year"
            
            @click="updateData('one_year')" :class="{active: selection==='one_year'}"
            >
          1Y
        </button>
        
        <button id="ytd"
            @click="updateData('ytd')" :class="{active: selection==='ytd'}"
            >
          YTD
        </button>
        
        <button id="all"
            @click="updateData('all')" :class="{active: selection==='all'}"
            >
          ALL
        </button>
      </div>
    
      <div id="chart-timeline">
      <vue-apex-charts type="area" height="350" ref="chart" :options="this.chartOptions" :series="this.chartOptions.series"></vue-apex-charts>
    </div>
    </div>
    </div>


    

</template>

<script>
import VueApexCharts from 'vue-apexcharts'
 

export default{
  components: {
    VueApexCharts,
  },
  data () {
    return {
        
          
          series: [{
           
          }],
          chartOptions: {
              series: [
                    {
                      name: 'Video Clicks',
                      data:  [
                                [1327359600000,30.95],
                                [1327446000000,31.34],
                                [1327532400000,31.18],
                                [1327618800000,31.05],
                                [1327878000000,31.00],
                                [1327964400000,30.95],
                                [1328050800000,31.24],
                                [1328137200000,31.29],
                                [1328223600000,31.85],
                                [1328482800000,31.86],
                                [1328569200000,32.28],
                                [1328655600000,32.10],
                                [1328742000000,32.65],
                                [1328828400000,32.21],
                                [1329087600000,32.35],
                                [1329174000000,32.44],
                                [1329260400000,32.46],
                                [1329346800000,32.86],
                                [1329433200000,32.75],
                                [1329778800000,32.54],
                                [1329865200000,32.33],
                                [1329951600000,32.97],
                                [1330038000000,33.41],
                                [1330297200000,33.27],
                                [1330383600000,33.27],
                                [1330470000000,32.89],
                                [1330556400000,33.10],
                                [1330642800000,33.73],
                                [1330902000000,33.22],
                                [1330988400000,31.99],
                                [1331074800000,32.41],
                                [1331161200000,33.05],
                                [1331247600000,33.64],
                                [1331506800000,33.56],
                                [1331593200000,34.22],
                                [1331679600000,33.77],
                                [1331766000000,34.17],
                                [1331852400000,33.82],
                                [1332111600000,34.51],
                                [1332198000000,33.16],
                                [1332284400000,33.56],
                                [1332370800000,33.71],
                                [1332457200000,33.81],
                                [1332712800000,34.40],
                                [1332799200000,34.63],
                                [1332885600000,34.46],
                                [1332972000000,34.48],
                                [1333058400000,34.31],
                                [1333317600000,34.70],
                                [1333404000000,34.31],
                                [1333490400000,33.46],
                                [1333576800000,33.59],
                                [1333922400000,33.22],
                                [1334008800000,32.61],
                                [1334095200000,33.01],
                                [1334181600000,33.55],
                                [1334268000000,33.18],
                                [1334527200000,32.84],
                                [1334613600000,33.84],
                                [1334700000000,33.39],
                                [1334786400000,32.91],
                                [1334872800000,33.06],
                                [1335132000000,32.62],
                                [1335218400000,32.40],
                                [1335304800000,33.13],
                                [1335391200000,33.26],
                                [1335477600000,33.58],
                                [1335736800000,33.55],
                                [1335823200000,33.77],
                                [1335909600000,33.76],
                                [1335996000000,33.32],
                                [1336082400000,32.61],
                                [1336341600000,32.52],
                                [1336428000000,32.67],
                                [1336514400000,32.52],
                                [1336600800000,31.92],
                                [1336687200000,32.20],
                                [1336946400000,32.23],
                                [1337032800000,32.33],
                                [1337119200000,32.36],
                                [1337205600000,32.01],
                                [1337292000000,31.31],
                                [1337551200000,32.01],
                                [1337637600000,32.01],
                                [1337724000000,32.18],
                                [1337810400000,31.54],
                                [1337896800000,31.60],
                                [1338242400000,32.05],
                                [1338328800000,31.29],
                                [1338415200000,31.05],
                                [1338501600000,29.82],
                                [1338760800000,30.31],
                                [1338847200000,30.70],
                                [1338933600000,31.69],
                                [1339020000000,31.32],
                                [1339106400000,31.65],
                                [1339365600000,31.13],
                                [1339452000000,31.77],
                                [1339538400000,31.79],
                                [1339624800000,31.67],
                                [1339711200000,32.39],
                                [1339970400000,32.63],
                                [1340056800000,32.89],
                                [1340143200000,31.99],
                                [1340229600000,31.23],
                                [1340316000000,31.57],
                                [1340575200000,30.84],
                                [1340661600000,31.07],
                                [1340748000000,31.41],
                                [1340834400000,31.17],
                                [1340920800000,32.37],
                                [1341180000000,32.19],
                                [1341266400000,32.51],
                                [1341439200000,32.53],
                                [1341525600000,31.37],
                                [1341784800000,30.43],
                                [1341871200000,30.44],
                                [1341957600000,30.20],
                                [1342044000000,30.14],
                                [1342130400000,30.65],
                                [1342389600000,30.40],
                                [1342476000000,30.65],
                                [1342562400000,31.43],
                                [1342648800000,31.89],
                                [1342735200000,31.38],
                                [1342994400000,30.64],
                                [1343080800000,30.02],
                                [1343167200000,30.33],
                                [1343253600000,30.95],
                                [1343340000000,31.89],
                                [1343599200000,31.01],
                                [1343685600000,30.88],
                                [1343772000000,30.69],
                                [1343858400000,30.58],
                                [1343944800000,32.02],
                                [1344204000000,32.14],
                                [1344290400000,32.37],
                                [1344376800000,32.51],
                                [1344463200000,32.65],
                                [1344549600000,32.64],
                                [1344808800000,32.27],
                                [1344895200000,32.10],
                                [1344981600000,32.91],
                                [1345068000000,33.65],
                                [1345154400000,33.80],
                                [1345413600000,33.92],
                                [1345500000000,33.75],
                                [1345586400000,33.84],
                                [1345672800000,33.50],
                                [1345759200000,32.26],
                                [1346018400000,32.32],
                                [1346104800000,32.06],
                                [1346191200000,31.96],
                                [1346277600000,31.46],
                                [1346364000000,31.27],
                                [1346709600000,31.43],
                                [1346796000000,32.26],
                                [1346882400000,32.79],
                                [1346968800000,32.46],
                                [1347228000000,32.13],
                                [1347314400000,32.43],
                                [1347400800000,32.42],
                                [1347487200000,32.81],
                                [1347573600000,33.34],
                                [1347832800000,33.41],
                                [1347919200000,32.57],
                                [1348005600000,33.12],
                                [1348092000000,34.53],
                                [1348178400000,33.83],
                                [1348437600000,33.41],
                                [1348524000000,32.90],
                                [1348610400000,32.53],
                                [1348696800000,32.80],
                                [1348783200000,32.44],
                                [1349042400000,32.62],
                                [1349128800000,32.57],
                                [1349215200000,32.60],
                                [1349301600000,32.68],
                                [1349388000000,32.47],
                                [1349647200000,32.23],
                                [1349733600000,31.68],
                                [1349820000000,31.51],
                                [1349906400000,31.78],
                                [1349992800000,31.94],
                                [1350252000000,32.33],
                                [1350338400000,33.24],
                                [1350424800000,33.44],
                                [1350511200000,33.48],
                                [1350597600000,33.24],
                                [1350856800000,33.49],
                                [1350943200000,33.31],
                                [1351029600000,33.36],
                                [1351116000000,33.40],
                                [1351202400000,34.01],
                                [1351638000000,34.02],
                                [1351724400000,34.36],
                                [1351810800000,34.39],
                                [1352070000000,34.24],
                                [1352156400000,34.39],
                                [1352242800000,33.47],
                                [1352329200000,32.98],
                                [1352415600000,32.90],
                                [1352674800000,32.70],
                                [1352761200000,32.54],
                                [1352847600000,32.23],
                                [1352934000000,32.64],
                                [1353020400000,32.65],
                                [1353279600000,32.92],
                                [1353366000000,32.64],
                                [1353452400000,32.84],
                                [1353625200000,33.40],
                                [1353884400000,33.30],
                                [1353970800000,33.18],
                                [1354057200000,33.88],
                                [1354143600000,34.09],
                                [1354230000000,34.61],
                                [1354489200000,34.70],
                                [1354575600000,35.30],
                                [1354662000000,35.40],
                                [1354748400000,35.14],
                                [1354834800000,35.48],
                                [1355094000000,35.75],
                                [1355180400000,35.54],
                                [1355266800000,35.96],
                                [1355353200000,35.53],
                                [1355439600000,37.56],
                                [1355698800000,37.42],
                                [1355785200000,37.49],
                                [1355871600000,38.09],
                                [1355958000000,37.87],
                                [1356044400000,37.71],
                                [1356303600000,37.53],
                                [1356476400000,37.55],
                                [1356562800000,37.30],
                                [1356649200000,36.90],
                                [1356908400000,37.68],
                                [1357081200000,38.34],
                                [1357167600000,37.75],
                                [1357254000000,38.13],
                                [1357513200000,37.94],
                                [1357599600000,38.14],
                                [1357686000000,38.66],
                                [1357772400000,38.62],
                                [1357858800000,38.09],
                                [1358118000000,38.16],
                                [1358204400000,38.15],
                                [1358290800000,37.88],
                                [1358377200000,37.73],
                                [1358463600000,37.98],
                                [1358809200000,37.95],
                                [1358895600000,38.25],
                                [1358982000000,38.10],
                                [1359068400000,38.32],
                                [1359327600000,38.24],
                                [1359414000000,38.52],
                                [1359500400000,37.94],
                                [1359586800000,37.83],
                                [1359673200000,38.34],
                                [1359932400000,38.10],
                                [1360018800000,38.51],
                                [1360105200000,38.40],
                                [1360191600000,38.07],
                                [1360278000000,39.12],
                                [1360537200000,38.64],
                                [1360623600000,38.89],
                                [1360710000000,38.81],
                                [1360796400000,38.61],
                                [1360882800000,38.63],
                                [1361228400000,38.99],
                                [1361314800000,38.77],
                                [1361401200000,38.34],
                                [1361487600000,38.55],
                                [1361746800000,38.11],
                                [1361833200000,38.59],
                                [1361919600000,39.60],
                              ]
                    },
                    {
                      name: 'Audio Clicks',
                      data:  [
                                [1327359600000,80.95],
                                [1327446000000,81.34],
                                [1327532400000,81.18],
                                [1327618800000,81.05],
                                [1327878000000,81.00],
                                [1327964400000,80.95],
                                [1328050800000,81.24],
                                [1328137200000,81.29],
                                [1328223600000,81.85],
                                [1328482800000,81.86],
                                [1328569200000,82.28],
                                [1328655600000,82.10],
                                [1328742000000,82.65],
                                [1328828400000,82.21],
                                [1329087600000,82.35],
                                [1329174000000,82.44],
                                [1329260400000,82.46],
                                [1329346800000,82.86],
                                [1329433200000,82.75],
                                [1329778800000,82.54],
                                [1329865200000,82.33],
                                [1329951600000,82.97],
                                [1330038000000,83.41],
                                [1330297200000,53.27],
                                [1330383600000,43.27],
                                [1330470000000,42.89],
                                [1330556400000,43.10],
                                [1330642800000,43.73],
                                [1330902000000,43.22],
                                [1330988400000,41.99],
                                [1331074800000,42.41],
                                [1331161200000,43.05],
                                [1331247600000,43.64],
                                [1331506800000,43.56],
                                [1331593200000,44.22],
                                [1331679600000,43.77],
                                [1331766000000,44.17],
                                [1331852400000,43.82],
                                [1332111600000,44.51],
                                [1332198000000,43.16],
                                [1332284400000,43.56],
                                [1332370800000,43.71],
                                [1332457200000,43.81],
                                [1332712800000,44.40],
                                [1332799200000,44.63],
                                [1332885600000,44.46],
                                [1332972000000,44.48],
                                [1333058400000,44.31],
                                [1333317600000,44.70],
                                [1333404000000,44.31],
                                [1333490400000,43.46],
                                [1333576800000,43.59],
                                [1333922400000,43.22],
                                [1334008800000,42.61],
                                [1334095200000,43.01],
                                [1334181600000,43.55],
                                [1334268000000,43.18],
                                [1334527200000,42.84],
                                [1334613600000,43.84],
                                [1334700000000,43.39],
                                [1334786400000,42.91],
                                [1334872800000,43.06],
                                [1335132000000,42.62],
                                [1335218400000,42.40],
                                [1335304800000,43.13],
                                [1335391200000,43.26],
                                [1335477600000,43.58],
                                [1335736800000,43.55],
                                [1335823200000,43.77],
                                [1335909600000,43.76],
                                [1335996000000,43.32],
                                [1336082400000,42.61],
                                [1336341600000,42.52],
                                [1336428000000,42.67],
                                [1336514400000,42.52],
                                [1336600800000,41.92],
                                [1336687200000,42.20],
                                [1336946400000,42.23],
                                [1337032800000,42.33],
                                [1337119200000,42.36],
                                [1337205600000,42.01],
                                [1337292000000,41.31],
                                [1337551200000,42.01],
                                [1337637600000,42.01],
                                [1337724000000,42.18],
                                [1337810400000,41.54],
                                [1337896800000,41.60],
                                [1338242400000,42.05],
                                [1338328800000,41.29],
                                [1338415200000,41.05],
                                [1338501600000,29.82],
                                [1338760800000,40.31],
                                [1338847200000,40.70],
                                [1338933600000,41.69],
                                [1339020000000,41.32],
                                [1339106400000,41.65],
                                [1339365600000,41.13],
                                [1339452000000,41.77],
                                [1339538400000,41.79],
                                [1339624800000,41.67],
                                [1339711200000,42.39],
                                [1339970400000,42.63],
                                [1340056800000,42.89],
                                [1340143200000,41.99],
                                [1340229600000,41.23],
                                [1340316000000,41.57],
                                [1340575200000,40.84],
                                [1340661600000,41.07],
                                [1340748000000,41.41],
                                [1340834400000,41.17],
                                [1340920800000,42.37],
                                [1341180000000,42.19],
                                [1341266400000,42.51],
                                [1341439200000,42.53],
                                [1341525600000,41.37],
                                [1341784800000,40.43],
                                [1341871200000,40.44],
                                [1341957600000,40.20],
                                [1342044000000,40.14],
                                [1342130400000,40.65],
                                [1342389600000,40.40],
                                [1342476000000,40.65],
                                [1342562400000,41.43],
                                [1342648800000,41.89],
                                [1342735200000,41.38],
                                [1342994400000,40.64],
                                [1343080800000,40.02],
                                [1343167200000,40.33],
                                [1343253600000,40.95],
                                [1343340000000,41.89],
                                [1343599200000,41.01],
                                [1343685600000,40.88],
                                [1343772000000,40.69],
                                [1343858400000,40.58],
                                [1343944800000,42.02],
                                [1344204000000,42.14],
                                [1344290400000,42.37],
                                [1344376800000,42.51],
                                [1344463200000,42.65],
                                [1344549600000,42.64],
                                [1344808800000,42.27],
                                [1344895200000,42.10],
                                [1344981600000,42.91],
                                [1345068000000,43.65],
                                [1345154400000,43.80],
                                [1345413600000,43.92],
                                [1345500000000,43.75],
                                [1345586400000,43.84],
                                [1345672800000,43.50],
                                [1345759200000,42.26],
                                [1346018400000,42.32],
                                [1346104800000,42.06],
                                [1346191200000,41.96],
                                [1346277600000,41.46],
                                [1346364000000,41.27],
                                [1346709600000,41.43],
                                [1346796000000,42.26],
                                [1346882400000,42.79],
                                [1346968800000,42.46],
                                [1347228000000,42.13],
                                [1347314400000,42.43],
                                [1347400800000,42.42],
                                [1347487200000,42.81],
                                [1347573600000,43.34],
                                [1347832800000,43.41],
                                [1347919200000,42.57],
                                [1348005600000,43.12],
                                [1348092000000,44.53],
                                [1348178400000,43.83],
                                [1348437600000,43.41],
                                [1348524000000,42.90],
                                [1348610400000,42.53],
                                [1348696800000,42.80],
                                [1348783200000,42.44],
                                [1349042400000,42.62],
                                [1349128800000,42.57],
                                [1349215200000,42.60],
                                [1349301600000,42.68],
                                [1349388000000,42.47],
                                [1349647200000,42.23],
                                [1349733600000,41.68],
                                [1349820000000,41.51],
                                [1349906400000,41.78],
                                [1349992800000,41.94],
                                [1350252000000,42.33],
                                [1350338400000,43.24],
                                [1350424800000,43.44],
                                [1350511200000,43.48],
                                [1350597600000,43.24],
                                [1350856800000,43.49],
                                [1350943200000,43.31],
                                [1351029600000,43.36],
                                [1351116000000,43.40],
                                [1351202400000,44.01],
                                [1351638000000,44.02],
                                [1351724400000,44.36],
                                [1351810800000,44.39],
                                [1352070000000,44.24],
                                [1352156400000,44.39],
                                [1352242800000,43.47],
                                [1352329200000,42.98],
                                [1352415600000,42.90],
                                [1352674800000,42.70],
                                [1352761200000,42.54],
                                [1352847600000,42.23],
                                [1352934000000,42.64],
                                [1353020400000,42.65],
                                [1353279600000,42.92],
                                [1353366000000,42.64],
                                [1353452400000,42.84],
                                [1353625200000,43.40],
                                [1353884400000,43.30],
                                [1353970800000,43.18],
                                [1354057200000,43.88],
                                [1354143600000,44.09],
                                [1354230000000,44.61],
                                [1354489200000,44.70],
                                [1354575600000,45.30],
                                [1354662000000,45.40],
                                [1354748400000,45.14],
                                [1354834800000,45.48],
                                [1355094000000,45.75],
                                [1355180400000,45.54],
                                [1355266800000,45.96],
                                [1355353200000,45.53],
                                [1355439600000,47.56],
                                [1355698800000,47.42],
                                [1355785200000,47.49],
                                [1355871600000,48.09],
                                [1355958000000,47.87],
                                [1356044400000,47.71],
                                [1356303600000,47.53],
                                [1356476400000,47.55],
                                [1356562800000,47.30],
                                [1356649200000,46.90],
                                [1356908400000,47.68],
                                [1357081200000,48.34],
                                [1357167600000,47.75],
                                [1357254000000,48.13],
                                [1357513200000,47.94],
                                [1357599600000,48.14],
                                [1357686000000,48.66],
                                [1357772400000,48.62],
                                [1357858800000,48.09],
                                [1358118000000,48.16],
                                [1358204400000,48.15],
                                [1358290800000,47.88],
                                [1358377200000,47.73],
                                [1358463600000,47.98],
                                [1358809200000,47.95],
                                [1358895600000,48.25],
                                [1358982000000,48.10],
                                [1359068400000,48.32],
                                [1359327600000,48.24],
                                [1359414000000,48.52],
                                [1359500400000,47.94],
                                [1359586800000,47.83],
                                [1359673200000,48.34],
                                [1359932400000,48.10],
                                [1360018800000,48.51],
                                [1360105200000,48.40],
                                [1360191600000,48.07],
                                [1360278000000,49.12],
                                [1360537200000,48.64],
                                [1360623600000,48.89],
                                [1360710000000,48.81],
                                [1360796400000,48.61],
                                [1360882800000,48.63],
                                [1361228400000,48.99],
                                [1361314800000,48.77],
                                [1361401200000,48.34],
                                [1361487600000,48.55],
                                [1361746800000,48.11],
                                [1361833200000,48.59],
                                [1361919600000,49.60],
                              ]
                    },
                    {
                      name: 'Video likes',
                      data:  [
                               [1327359600000,80.95],
                                [1327446000000,81.34],
                                [1327532400000,81.18],
                                [1327618800000,81.05],
                                [1327878000000,81.00],
                                [1327964400000,80.95],
                                [1328050800000,81.24],
                                [1328137200000,81.29],
                                [1328223600000,81.85],
                                [1328482800000,81.86],
                                [1328569200000,82.28],
                                [1328655600000,82.10],
                                [1328742000000,82.65],
                                [1328828400000,82.21],
                                [1329087600000,82.35],
                                [1329174000000,82.44],
                                [1329260400000,82.46],
                                [1329346800000,82.86],
                                [1329433200000,82.75],
                                [1329778800000,82.54],
                                [1329865200000,82.33],
                                [1329951600000,82.97],
                                [1330038000000,83.41],
                                [1330297200000,53.27],
                                [1330383600000,63.27],
                                [1330470000000,62.89],
                                [1330556400000,63.10],
                                [1330642800000,63.73],
                                [1330902000000,63.22],
                                [1330988400000,61.99],
                                [1331074800000,62.41],
                                [1331161200000,63.05],
                                [1331247600000,63.64],
                                [1331506800000,63.56],
                                [1331593200000,64.22],
                                [1331679600000,63.77],
                                [1331766000000,64.17],
                                [1331852400000,63.82],
                                [1332111600000,64.51],
                                [1332198000000,63.16],
                                [1332284400000,63.56],
                                [1332370800000,63.71],
                                [1332457200000,63.81],
                                [1332712800000,64.40],
                                [1332799200000,64.63],
                                [1332885600000,64.46],
                                [1332972000000,64.48],
                                [1333058400000,64.31],
                                [1333317600000,64.70],
                                [1333404000000,64.31],
                                [1333490400000,63.46],
                                [1333576800000,63.59],
                                [1333922400000,63.22],
                                [1334008800000,62.61],
                                [1334095200000,63.01],
                                [1334181600000,63.55],
                                [1334268000000,63.18],
                                [1334527200000,62.84],
                                [1334613600000,63.84],
                                [1334700000000,63.39],
                                [1334786400000,62.91],
                                [1334872800000,63.06],
                                [1335132000000,62.62],
                                [1335218400000,62.40],
                                [1335304800000,63.13],
                                [1335391200000,63.26],
                                [1335477600000,63.58],
                                [1335736800000,63.55],
                                [1335823200000,63.77],
                                [1335909600000,63.76],
                                [1335996000000,63.32],
                                [1336082400000,62.61],
                                [1336341600000,62.52],
                                [1336428000000,62.67],
                                [1336514400000,62.52],
                                [1336600800000,61.92],
                                [1336687200000,62.20],
                                [1336946400000,62.23],
                                [1337032800000,62.33],
                                [1337119200000,62.36],
                                [1337205600000,62.01],
                                [1337292000000,61.31],
                                [1337551200000,62.01],
                                [1337637600000,62.01],
                                [1337724000000,62.18],
                                [1337810400000,61.54],
                                [1337896800000,61.60],
                                [1338242400000,62.05],
                                [1338328800000,61.29],
                                [1338415200000,61.05],
                                [1338501600000,29.82],
                                [1338760800000,60.31],
                                [1338847200000,60.70],
                                [1338933600000,61.69],
                                [1339020000000,61.32],
                                [1339106400000,61.65],
                                [1339365600000,61.13],
                                [1339452000000,61.77],
                                [1339538400000,61.79],
                                [1339624800000,61.67],
                                [1339711200000,62.39],
                                [1339970400000,62.63],
                                [1340056800000,62.89],
                                [1340143200000,61.99],
                                [1340229600000,61.23],
                                [1340316000000,61.57],
                                [1340575200000,60.84],
                                [1340661600000,61.07],
                                [1340748000000,61.41],
                                [1340834400000,61.17],
                                [1340920800000,62.37],
                                [1341180000000,62.19],
                                [1341266400000,62.51],
                                [1341439200000,62.53],
                                [1341525600000,61.37],
                                [1341784800000,60.43],
                                [1341871200000,60.44],
                                [1341957600000,60.20],
                                [1342044000000,60.14],
                                [1342130400000,60.65],
                                [1342389600000,60.40],
                                [1342476000000,60.65],
                                [1342562400000,61.43],
                                [1342648800000,61.89],
                                [1342735200000,61.38],
                                [1342994400000,60.64],
                                [1343080800000,60.02],
                                [1343167200000,60.33],
                                [1343253600000,60.95],
                                [1343340000000,61.89],
                                [1343599200000,61.01],
                                [1343685600000,60.88],
                                [1343772000000,60.69],
                                [1343858400000,60.58],
                                [1343944800000,62.02],
                                [1344204000000,62.14],
                                [1344290400000,62.37],
                                [1344376800000,62.51],
                                [1344463200000,62.65],
                                [1344549600000,62.64],
                                [1344808800000,62.27],
                                [1344895200000,62.10],
                                [1344981600000,62.91],
                                [1345068000000,63.65],
                                [1345154400000,63.80],
                                [1345413600000,63.92],
                                [1345500000000,63.75],
                                [1345586400000,63.84],
                                [1345672800000,63.50],
                                [1345759200000,62.26],
                                [1346018400000,62.32],
                                [1346104800000,62.06],
                                [1346191200000,61.96],
                                [1346277600000,61.46],
                                [1346364000000,61.27],
                                [1346709600000,61.43],
                                [1346796000000,62.26],
                                [1346882400000,62.79],
                                [1346968800000,62.46],
                                [1347228000000,62.13],
                                [1347314400000,62.43],
                                [1347400800000,62.42],
                                [1347487200000,62.81],
                                [1347573600000,63.34],
                                [1347832800000,63.41],
                                [1347919200000,62.57],
                                [1348005600000,63.12],
                                [1348092000000,64.53],
                                [1348178400000,63.83],
                                [1348437600000,63.41],
                                [1348524000000,62.90],
                                [1348610400000,62.53],
                                [1348696800000,62.80],
                                [1348783200000,62.44],
                                [1349042400000,62.62],
                                [1349128800000,62.57],
                                [1349215200000,62.60],
                                [1349301600000,62.68],
                                [1349388000000,62.47],
                                [1349647200000,62.23],
                                [1349733600000,61.68],
                                [1349820000000,61.51],
                                [1349906400000,61.78],
                                [1349992800000,61.94],
                                [1350252000000,62.33],
                                [1350338400000,63.24],
                                [1350424800000,63.44],
                                [1350511200000,63.48],
                                [1350597600000,63.24],
                                [1350856800000,63.49],
                                [1350943200000,63.31],
                                [1351029600000,63.36],
                                [1351116000000,63.40],
                                [1351202400000,64.01],
                                [1351638000000,64.02],
                                [1351724400000,64.36],
                                [1351810800000,64.39],
                                [1352070000000,64.24],
                                [1352156400000,64.39],
                                [1352242800000,63.47],
                                [1352329200000,62.98],
                                [1352415600000,62.90],
                                [1352674800000,62.70],
                                [1352761200000,62.54],
                                [1352847600000,62.23],
                                [1352934000000,62.64],
                                [1353020400000,62.65],
                                [1353279600000,62.92],
                                [1353366000000,62.64],
                                [1353452400000,62.84],
                                [1353625200000,63.40],
                                [1353884400000,63.30],
                                [1353970800000,63.18],
                                [1354057200000,63.88],
                                [1354143600000,64.09],
                                [1354230000000,64.61],
                                [1354489200000,64.70],
                                [1354575600000,65.30],
                                [1354662000000,65.40],
                                [1354748400000,65.14],
                                [1354834800000,65.48],
                                [1355094000000,65.75],
                                [1355180400000,65.54],
                                [1355266800000,65.96],
                                [1355353200000,65.53],
                                [1355439600000,67.56],
                                [1355698800000,67.42],
                                [1355785200000,67.49],
                                [1355871600000,68.09],
                                [1355958000000,67.87],
                                [1356044400000,67.71],
                                [1356303600000,67.53],
                                [1356476400000,67.55],
                                [1356562800000,67.30],
                                [1356649200000,66.90],
                                [1356908400000,67.68],
                                [1357081200000,68.34],
                                [1357167600000,67.75],
                                [1357254000000,68.13],
                                [1357513200000,67.94],
                                [1357599600000,68.14],
                                [1357686000000,68.66],
                                [1357772400000,68.62],
                                [1357858800000,68.09],
                                [1358118000000,68.16],
                                [1358204400000,68.15],
                                [1358290800000,67.88],
                                [1358377200000,67.73],
                                [1358463600000,67.98],
                                [1358809200000,67.95],
                                [1358895600000,68.25],
                                [1358982000000,68.10],
                                [1359068400000,68.32],
                                [1359327600000,68.24],
                                [1359414000000,68.52],
                                [1359500400000,67.94],
                                [1359586800000,67.83],
                                [1359673200000,68.34],
                                [1359932400000,68.10],
                                [1360018800000,68.51],
                                [1360105200000,68.40],
                                [1360191600000,68.07],
                                [1360278000000,69.12],
                                [1360537200000,68.64],
                                [1360623600000,68.89],
                                [1360710000000,68.81],
                                [1360796400000,68.61],
                                [1360882800000,68.63],
                                [1361228400000,68.99],
                                [1361314800000,68.77],
                                [1361401200000,68.34],
                                [1361487600000,68.55],
                                [1361746800000,68.11],
                                [1361833200000,68.59],
                                [1361919600000,69.60],
                              ]
                    }
                   
                  ],
                          chart: {
              id: 'area-datetime',
              type: 'area',
              height: 350,
              zoom: {
                autoScaleYaxis: true
              }
            },
            annotations: {
              yaxis: [{
                y: 30,
                borderColor: '#999',
                label: {
                  show: true,
                  text: 'Support',
                  style: {
                    color: "#fff",
                    background: '#00E396'
                  }
                }
              }],
              xaxis: [{
                x: new Date('14 Nov 2012').getTime(),
                borderColor: '#999',
                yAxisIndex: 0,
                label: {
                  show: true,
                  text: 'Rally',
                  style: {
                    color: "#fff",
                    background: '#775DD0'
                  }
                }
              }]
            },
            dataLabels: {
              enabled: false
            },
            markers: {
              size: 0,
              style: 'hollow',
            },
            xaxis: {
              type: 'datetime',
              min: new Date('01 Mar 2012').getTime(),
              tickAmount: 6,
            },
            tooltip: {
              x: {
                format: 'dd MMM yyyy'
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100]
              }
            },
          },
          
          selection: 'one_year',
    }
  },
   methods: {
          updateData: function(timeline) {
            this.selection = timeline
        
            switch (timeline) {
              case 'one_month':
                this.$refs.chart.zoomX(
                  new Date('28 Jan 2013').getTime(),
                  new Date('07 Feb 2013').getTime()
                )
                break
              case 'six_months':
                this.$refs.chart.zoomX(
                  new Date('27 Sep 2012').getTime(),
                  new Date('27 Feb 2013').getTime()
                )
                break
              case 'one_year':
                this.$refs.chart.zoomX(
                  new Date('27 Feb 2012').getTime(),
                  new Date('27 Feb 2013').getTime()
                )
                break
              case 'ytd':
                this.$refs.chart.zoomX(
                  new Date('01 Jan 2013').getTime(),
                  new Date('27 Feb 2013').getTime()
                )
                break
              case 'all':
                this.$refs.chart.zoomX(
                  new Date('23 Jan 2012').getTime(),
                  new Date('27 Feb 2013').getTime()
                )
                break
              default:
            }
          }
        },
  computed: { 
  },
  mounted () {
  },
  created () { 
 
  }
}
</script>

 